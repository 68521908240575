$idy-color-primary: #2b1f86;
$idy-color-primary-alt: #512da8;
$idy-btn-color-default: #4531e1;
$idy-color-secondary: #3E2ADA;
$idy-btn-color-secondary: #ededf4;
$idy-color-default: #545454;
$idy-color-info: #5a567c;
$idy-color-placeholder: #9f9cb5;
$idy-color-green-default: #2fdec4;
$idy-color-green-primary: #97e2d7;
$idy-color-green-secondary: #b1f3e9;
$idy-color-progress-primary: #454165;
$idy-color-progress-secondary: #8A8ED7;
$idy-color-red: #b00020;
