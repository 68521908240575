.loaderMainDiv {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
}
.loaderBgDiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.7;
}
.loaderInnerDiv {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.imgSize {
    width: 100px;
    height: 100px;
}
