.root {
    flex-grow: 1;
}
.menuButton {
    margin-right: px;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.filter {
    width: 30%;
    margin-bottom: 1rem !important;
}

.toolbar {
    padding-left: 0;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.media {
    height: 0;
    padding-top: 56.25%;
    margin: 0.5rem 0;
}
.expand {
    transform: rotate(0deg);
    margin-left: auto;
    transition: transform 120ms linear;
}
.expandOpen {
    transform: rotate(180deg);
}
.comment {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    padding: 18.5px 14px;
    border-radius: 4px;
}
.backBtn {
    margin: 2px;
}
.commentLabel {
    margin: 2px;
}
